<template>
  <div v-if="fullPractice" class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ this.pageTitle }}</h1>
    <div class="mainContent">
      <h2>{{ $t("practice.timeRemaining") }}</h2>
      <div class="timer">
        {{ instant.formatSecondToMMSS(limitTime - accumulationSecond) }}
      </div>
      <h2>{{ $t("practice.reminder") }}</h2>
      <ol>
        <li>
          {{ $t("practice.ifYouWantToEndTheTimer1") }}

          <span class="endTimeButton" @click="() => nextSection()">{{
            $t("practice.here")
          }}</span>
          {{ $t("practice.ifYouWantToEndTheTimer2") }}
        </li>
      </ol>
      <hr />
      <!-- <h2>{{ $t("practice.form.section") }}</h2>s -->
      <div style="width: 100%;margin-bottom:30px">
        <el-row class="staticSectionRow title">
          <el-col :span="2">#</el-col>
          <el-col v-if="isScoringTypeSatOrAct" :span="4">{{
            $t("practice.form.section")
          }}</el-col>
          <el-col :span="10">{{ $t("practice.form.name") }}</el-col>
          <el-col :span="3">{{ $t("column.questionCount") }}</el-col>
          <el-col :span="3">{{ $t("column.time") }}</el-col>
          <el-col :span="2">{{ $t("practice.form.isFinish") }}</el-col>
        </el-row>
        <div
          :key="practice.id"
          v-for="(practice, index) in fullPractice.compose_practice_practices"
        >
          <el-row class="staticSectionRow td">
            <el-col :span="2">{{ index + 1 }}</el-col>
            <el-col v-if="isScoringTypeSatOrAct" :span="4">
              {{ scoringType[fullPractice.scoring_type][practice.scoring_type] }}
            </el-col>
            <el-col :span="10">{{ practice.practice.exam.title }}</el-col>
            <el-col :span="3">{{
              practice.practice.exam.questions.length
            }}</el-col>
            <el-col :span="3">
              {{
                practice.practice.has_timing
                  ? instant.formatSecondToMMSS(
                      practice.practice.timing_minutes * 60 +
                        practice.practice.timing_seconds
                    )
                  : "--:--"
              }}
            </el-col>
            <el-col :span="2">
              <i
                v-if="practice.order <= currentSection"
                class="fas fa-check action-icon"
              />
            </el-col>
          </el-row>
          <el-row
            v-if="index < fullPractice.compose_practice_practices.length - 1"
            class="staticSectionRow td"
          >
            <el-col :span="2"
              ><div style="visibility: hidden">Empty Block</div></el-col
            >
            <el-col v-if="isScoringTypeSatOrAct" :span="4">
              <div style="visibility: hidden">Empty Block</div>
            </el-col>
            <el-col :span="13">Break</el-col>
            <el-col :span="2">
              {{ instant.formatSecondToMMSS(practice.break_seconds) }}
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import FullPractice from "@/views/practices/FullPractice";
import Breadcrumb from "@/components/Breadcrumb";
import fullPracticesApi from "@/apis/fullPractices";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb },
  data() {
    return {
      fullPractice: null,
      userExam: null,
      accumulationSecond: 0
    };
  },
  computed: {
    instant() {
      return instant;
    },
    scoringType() {
      return {
        SAT: {
          reading: "Reading",
          writing: "Writing",
          math: "Math",
          math_calculator: "Math Calculator"
        },
        ACT: {
          science: "Science",
          english: "English",
          reading: "Reading",
          math: "Math"
        }
      };
    },
    pageTitle() {
      return this.fullPractice ? this.fullPractice.exam.title : "";
    },
    currentSection() {
      return Number(this.userExam.other_status[0].currentSection);
    },
    limitTime() {
      if (!this.fullPractice) return Number.MAX_SAFE_INTEGER;
      return this.fullPractice.compose_practice_practices[
        this.currentSection - 1
      ].break_seconds;
    },
    localStoragePracticeKey() {
      return `PRACTICE_${this.$route.params.fullPracticeId}_SECTION_${this.currentSection}_BREAK_TIMER`;
    },
    isScoringTypeSatOrAct() {
      return (
        this.fullPractice.scoring_type === "SAT" ||
        this.fullPractice.scoring_type === "ACT"
      );
    }
  },
  async created() {
    await this.fetchUserExam();
    await this.fetchFullPractice();
    if (!this.userExam.other_status[0].isBreakTime) {
      this.nextSection();
    }
    const otherStatus = this.userExam.other_status;
    if (!localStorage.getItem(this.localStoragePracticeKey)) {
      localStorage.setItem(
        this.localStoragePracticeKey,
        otherStatus
          ? otherStatus[0][`setion${this.currentSection}BreakProgress_time`]
          : 0
      );
    }
    this.accumulationSecond =
      Number(localStorage.getItem(this.localStoragePracticeKey)) || 0;

    this.timerInterval = setInterval(() => {
      const accumulationSecond =
        Number(localStorage.getItem(this.localStoragePracticeKey)) || 0;
      this.accumulationSecond = accumulationSecond;
      if (this.limitTime <= this.accumulationSecond) {
        this.nextSection();
      } else {
        localStorage.setItem(
          this.localStoragePracticeKey,
          accumulationSecond + 1
        );
      }
      if (accumulationSecond % 5 === 0) {
        fullPracticesApi.updateFullPracticeExamStatus(this.userExam.id, [
          {
            ...otherStatus[0],
            [`section${this.currentSection}break_time`]: accumulationSecond
          }
        ]);
      }
    }, 1000);
  },
  methods: {
    async fetchFullPractice() {
      const { compose_practice } = await fullPracticesApi.fetchFullPractice(
        this.$route.params.fullPracticeId
      );
      this.fullPractice = new FullPractice(compose_practice);
    },
    async fetchUserExam() {
      const { user_exam } = await fullPracticesApi.takeFullPracticeExam(
        this.$route.params.fullPracticeId,
        { user_exam_id: this.$route.params.userExamId }
      );
      this.userExam = user_exam;
    },
    async nextSection() {
      clearInterval(this.timerInterval);
      localStorage.removeItem(this.localStoragePracticeKey);
      fullPracticesApi.updateFullPracticeExamStatus(this.userExam.id, [
        {
          ...this.userExam.other_status[0],
          currentSection: Number(this.currentSection) + 1,
          [`section${this.currentSection}break_time`]: this.accumulationSecond
        }
      ]);
      this.$router.push({
        name: "PracticeSection",
        params: {
          fullPracticeId: this.$route.params.fullPracticeId,
          userExamId: this.$route.params.userExamId,
          sectionOrder: Number(this.currentSection) + 1,
          questionOrder: 1
        }
      });
    }
  }
};
</script>

<style>
.questionOrderLink {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border: #ddd 4px solid;
  margin: 0px 8px 8px 0px;
}

.activeQuestion {
  border: #42a16a 4px solid;
}

.questionOrderLink:hover {
  border: #42a16a 4px solid;
}

.title {
  font-weight: bold;
  color: #909399;
}

.td {
  color: #606266;
}

.staticSectionRow {
  padding: 8px 20px;
  border-bottom: 1px solid #ebeef5;
  font-size: 14px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer {
  border: none;
  border-radius: 20px;
  background-color: #31b0d5;
  color: #fff;
  padding: 0 25px;
  height: 200px;
  font-size: 180px;
  line-height: 200px;
  margin: 20px 0px;
}

.endTimeButton {
  cursor: pointer;
  color: #42a16a;
}
</style>
